$(document).ready(function () {

	/* =====  MOBILE MENU TOGGLE  ====== */
	$('.offCanvasToggler').click(function (event) {
		event.preventDefault();
		$('.off-canvas-nav').toggleClass('active');
		$('.fullscreen-overlay').toggleClass('active');
		$('html, body').toggleClass('no-scrolling');
	});

	$('.home-slider-slides').slick({
		nextArrow: $('.home-slider-controls .next-slide'),
		prevArrow: $('.home-slider-controls .previous-slide'),
		autoplay: true,
		autoplaySpeed: 5000,
		dots: false
	});

	$('.full-width-slider-slides').slick({
		nextArrow: $('.slider-controls .next-slide'),
		prevArrow: $('.slider-controls .previous-slide'),
		autoplay: true,
		autoplaySpeed: 5000,
		dots: false
	});

	$('.datum').pickadate({
		// Strings and translations
		monthsFull: ['Januari', 'Februari', 'Maart', 'April', 'Mei', 'Juni', 'Juli', 'Augustus', 'September', 'Oktober', 'November', 'December'],
		monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
		weekdaysFull: ['Zondag', 'Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag'],
		weekdaysShort: ['Zo', 'Maa', 'Din', 'Woe', 'Don', 'Vr', 'Zat'],

		// Buttons
		today: 'Vandaag',
		clear: 'Wis',
		close: 'Sluit',

		// Accessibility labels
		labelMonthNext: 'Volgende maand',
		labelMonthPrev: 'Vorige maand',
		labelMonthSelect: 'Kies een maand',
		labelYearSelect: 'Kies een jaar',

		// Formats
		format: 'd mmmm',
		formatSubmit: 'd mmmm yyyy',
		hiddenName: true,

		// First day of the week
		firstDay: 7,

		// Disable dates
		disable: [1]
	});

	$('.tijd').pickatime({
		// Translations and clear button
		clear: 'Wis',

		// Formats
		format: 'HH:i',

		// Time intervals
		interval: 30,

		// Time limits
		min: [9, 0],
		max: [18, 30]
	});


	/* =====  Fancybox  ====== */
	$(".gallery").fancybox();
});
